<template >
  <div class="px-10">
    <div class="text-h4 primary--text mb-5">
      {{ isUpdate ? "แก้ไข" : "สร้าง" }}รถผิดระเบียบ
    </div>
    <!--<input type="text" id="name" class="form-control" name="name"  v-model="form.name" :disabled="validated ? '' : disabled">-->
    <v-card>
      <v-card-text>
        <!--บรรทัดรูป-->
        <v-row justify="space-around">
          <v-col cols="4 " class="text-h6 secondary--text"> ข้อมูลรถ </v-col>
          <v-col cols="6" class="text-h6 secondary--text">
            ข้อมูลรถปัจจุบัน
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="1">
            <div class="mt-8 grey--text">รูปรถ</div>
          </v-col>
          <v-col>
            <uploadImage
              directory="bancar/picture"
              :limit="2"
              :src="form.car_picture"
              @onChangeUrl="form.car_picture = $event"
            >
              <v-avatar color="primary" size="200" class="pointer">
                <img
                  :src="form.car_picture || $defaultImage"
                  width="200"
                  height="200"
                />
              </v-avatar>
            </uploadImage>
            <v-col>
              <v-btn
                class="btn btn-success ml-5 px-5"
                color="grey"
                dense
                small
                @click="removePicture('car_picture')"
              >
                <v-icon left> mdi mdi-delete </v-icon> ลบรูปภาพ
              </v-btn>
            </v-col>
          </v-col>
          <!---<v-col cols="1"></v-col>-->
          <v-col cols="2">
            <div class="mt-8 grey--text">รูปรถ</div>
          </v-col>
          <v-col>
            <!--แบ่งหน้าแนวตั้ง-->
            <uploadImage
              directory="bancar/present_picture"
              :limit="2"
              :src="form.car_present_picture"
              @onChangeUrl="form.car_present_picture = $event"
            >
              <v-avatar color="primary" size="200" class="pointer">
                <img
                  :src="form.car_present_picture || $defaultImage"
                  width="200"
                  height="200"
                />
              </v-avatar>
            </uploadImage>
            <v-col>
              <v-btn
                class="btn btn-success ml-5 px-5"
                color="grey"
                dense
                small
                @click="removePicture('car_present_picture')"
              >
                <v-icon left>mdi mdi-delete </v-icon> ลบรูปภาพ
              </v-btn>
            </v-col>
          </v-col>
        </v-row>

        <v-row justify="space-around">
          <v-col cols=""></v-col>
          <v-col cols="1" class="mt-3 grey--text" md="2">เลขทะเบียน</v-col>
          <v-col cols="1">
            <v-text-field
              name="car_plate_character"
              v-model="form.car_plate_character"
              label=""
              :error-messages="
                $v.form.car_plate_character.$error ? $error_text : ''
              "
              outlined
              dense
            />
          </v-col>
          <div class="mt-5 grey--text">-</div>
          <v-col cols="2">
            <v-text-field
              name="car_plate_number"
              v-model="form.car_plate_number"
              label=""
              outlined
              dense
              :error-messages="
                $v.form.car_plate_number.$error ? $error_text : ''
              "
            />
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="1" md="2" class="mt-3 grey--text">
            <div>ข้อมูลตรวจสภาพรถ</div>
          </v-col>
          <v-col cols="3">
            <v-radio-group
              class="mt-0"
              row
              dense
              v-model="form.is_inspect_pass"
            >
              <v-radio name="" label="ไม่ผ่าน" :value="false"></v-radio>
              <v-radio name="" label="ผ่าน" :value="true"></v-radio>
              <v-spacer />
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row justify="space-around">
          <v-col cols="1"></v-col>
          <v-col cols="1" class="mt-3 grey--text" md="2">
            <div>จังหวัด</div>
          </v-col>
          <v-col cols="3">
            <v-select
              item-value="value"
              item-text="name"
              v-model="form.car_plate_province"
              :error-messages="
                $v.form.car_plate_province.$error ? $error_text : ''
              "
              label=""
              :items="list.provinces"
              :menu-props="{ maxHeight: '400' }"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <!--วันที่ผ่าน-->
          <v-col cols="1"></v-col>
          <v-col cols="1">วันที่ผ่าน</v-col>
          <v-col cols="3">
            <DatePicker
              label=""
              :value="form.pass_date"
              @onChange="(val) => (form.pass_date = val)"
            />
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <v-row justify="space-around">
          <v-col cols="1"></v-col>
          <v-col cols="1" class="mt-7" md="2">
            <div>วันที่โดนแบน</div>
            <v-col cols="5"></v-col>
            <div>สาเหตุที่โดนแบน</div>
          </v-col>
          <v-col cols="3">
            <DatePicker
              label=""
              :value="form.ban_date"
              @onChange="(val) => (form.ban_date = val)"
            />
            <v-col cols="1"></v-col>
            <v-text-field
              name="ban_reason"
              v-model="form.ban_reason"
              label=""
              outlined
              dense
            />
          </v-col>

          <v-col cols="1"></v-col>

          <v-col cols="1">หมายเหตุ</v-col>
          <v-col cols="3">
            <v-textarea
              name="note"
              v-model="form.pass_note"
              label=""
              outlined
              dense
            />
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <v-row justify="space-around">
          <v-col cols="1"></v-col>
          <v-col cols="2">หมายเหตุ</v-col>
          <v-col cols="3">
            <v-textarea
              name="note"
              v-model="form.ban_note"
              label=""
              outlined
              dense
            />
          </v-col>

          <v-col cols="6"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ปิด</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >บันทึกข้อมูล</v-btn
        >
      </v-col>
    </v-row>
    <!--
        <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
            <AddressDetail :item="dialogAddressData" @onClose="dialogAddress = false" @onSubmit="onSubmitAddress" />
        </v-dialog>

            *************
            <v-dialog persistent scrollable max-width="1200" v-model="dialogUpdate">
            <ProductDetail :id="insurance_id" @onClose="dialogUpdate = false" @success="getInsurance()" />
            </v-dialog> -->
    <!--
            <v-dialog persistent scrollable max-width="1200" v-model="dialogUpdate">
                <ProductDetail
                :id="ban_car_id"
                @onClose="dialogUpdate = false"
                :main_branch="{ _id: form.branch_id, name: branch_name }"
                :sub_branch="{ _id: form._id, name: form.name }"
                @success="getProducts"
            />
            </v-dialog>
-->
  </div>
</template>

<script>
import AddressDetail from "@/components/Address/AddressDetail.vue";
import uploadImage from "@/components/uploadImage.vue"; /*รูป*/
import DatePicker from "@/components/DatePicker.vue"; /*วัน*/
import TimePicker from "@/components/TimePicker.vue"; /*เวลา*/
import S3 from 'aws-s3';

import {
  required,
  maxLength,
  minLength,
  numeric,
  minValue,
} from "vuelidate/lib/validators";

import * as moment from "moment";

export default {
  components: {
    AddressDetail,
    uploadImage,
    DatePicker,
    TimePicker,
  },
  validations: {
    form: {
      car_plate_character: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(4),
      },
      car_plate_number: { required, numeric, minValue: minValue(4) },
      car_plate_province: { required },
      /*car_plate: {required},ทะเบียนรถ*/
      logo_url: "",
    },
  },
  data: () => ({
    modal: {
      pass_date: false,
      ban_date: false,
      disabled: false,
    },
    loading: false,

    filter: {
      item_per_page: 20,
      page: 1,
      start_with: "",
    },

    list: {
      provinces: [],
    },

    form: {
      branch_id: "",
      car_picture: "",
      car_plate_character: "",
      car_plate_number: "",
      car_plate_province: "",
      ban_date: "",
      ban_reason: "",
      ban_note: "",

      car_present_picture: "",
      is_inspect_pass: true,
      pass_date: "",
      pass_note: "",
    },
  }),
  created() {
    this.getProvinces();
    if (this.isUpdate) this.getData();
    this.form.branch_id = this.$store.state.selected_branch._id;
  },
  methods: {
    async getProvinces() {
      this.list.provinces = this.$store.state.list.provinces
    },
    async getData() {
      if (this.$route.params.id) {
        let filter = {
          ban_car_id: this.$route.params.id,
        };
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios.post(`${this.$baseUrl}/ban_car/get_by_id`, body)
          .then((res) => {
            this.form = res.result;
          })
          .catch((err) => {
            this.$router.push({ name: "car-bancar" });
          });
      }
    },
    edit(item) {
      this.$router.push({ name: "car-bancar-update", params: { id: item.id } });
    },

    async deleteS3File(fileUrl) {      
      var array = fileUrl.split('/')
      var filePath = array.slice(3, array.length-1).join('/')
      var fileName = array[array.length-1]

      var aws = require('aws-sdk');
      var params = {
        Bucket: 'permtang',
        Key: filePath+'/'+fileName
      }
      const config = {
        bucketName: 'permtang',
        region: 'ap-southeast-1',
        accessKeyId: 'AKIA2ETG3H3HFJHMS4JA',
        secretAccessKey: '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',
        s3Url: 'https://permtang.s3-ap-southeast-1.amazonaws.com'
      };
      var s3 = new aws.S3(config);
      await s3.headObject(params).promise()
      console.log("File Found in S3")
      try {
          await s3.deleteObject(params).promise()
          console.log("file deleted Successfully")
      }
      catch (err) {
          console.log("ERROR in file Deleting : " + JSON.stringify(err))
      }
    },
    removePicture(picture) {
      var fileUrl = picture=='car_picture' ? this.form.car_picture : this.form.car_present_picture
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then( async (result) => {
        if (result.isConfirmed) {
          let filter = {
            ban_car_id: this.$route.params.id,
            car_picture: picture=='car_present_picture' ? this.form.car_picture : '',
            car_present_picture: picture=='car_picture' ? this.form.car_present_picture : '',
            is_inspect_pass: this.form.is_inspect_pass,
            pass_date: this.form.pass_date,
            pass_note: this.form.pass_note
          };
          let body = {
            token: this.$jwt.sign(filter, this.$privateKey, {noTimestamp: true})
          };
          this.deleteS3File(fileUrl)
          await this.$axios.post(`${this.$baseUrl}/ban_car/edit_ban_car`, body)
            .then((res) => {
              this.$alertSuccess({ title: res.status });
              this.getData();
            })
            .catch((err) => {
              this.$alertServerError({ title: err.error_message });
            });
        }
      });
    },
    close() {
      this.$router.push({ name: "car-bancar" });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = "add_ban_car";
            if (this.isUpdate) {
              subPath = "edit_ban_car";
              this.form = { ban_car_id: this.form._id, ...this.form };
              delete this.form._id;
            }
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };

            await this.$axios
              .post(`${this.$baseUrl}/ban_car/${subPath}`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                if (!this.isUpdate) this.$router.push({ name: "car-bancar" });
              })
              .catch((err) => {
                console.log("err", err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
        }
      );
    },
    deleteImage(index) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then( async (result) => {
          this.form.product.splice(index, 1);
          if (index == 0) {
            let data = {
              ban_car_id: "",
              car_picture: "",
              car_present_picture: "",
              is_inspect_pass: "",
              pass_date: moment()
                .set({ hour: 0, minute: 0, second: 0 })
                .format("YYYY-MM-DDTHH:mm:ss"),
              pass_note: "",
            };

            this.form.product.splice(0, 0, data);
          } else if (this.form.product.length == 1) {
            //
          }
      });
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>